import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanka.dev"
        }}>{`Grafana Tanka`}</a>{`: robust configuration utilitly for
Kubernetes, powered by Jsonnet`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jsonnet-libs"
        }}>{`jsonnet-libs`}</a>{`: ecosystem of Jsonnet libraries,
including Kubernetes`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nasm.netlify.app"
        }}>{`play64`}</a>{`: `}<inlineCode parentName="li">{`x86_64`}</inlineCode>{` assembly playground`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      