import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Tom, a Software Engineer at `}<a parentName="p" {...{
        "href": "https://grafana.com"
      }}>{`Grafana Labs`}</a>{`
working on `}<a parentName="p" {...{
        "href": "https://prometheus.io"
      }}>{`Prometheus`}</a>{` and `}<a parentName="p" {...{
        "href": "https://opentelemetry.io"
      }}>{`OpenTelemetry`}</a>{`.`}</p>
    <p>{`Previously built `}<a parentName="p" {...{
        "href": "https://tanka.dev"
      }}>{`Tanka`}</a>{`.`}</p>
    <p>{`Sometimes I write about things I find interesting here.
Also see my posts on the `}<a parentName="p" {...{
        "href": "https://grafana.com/author/tombraack/"
      }}>{`Grafana Blog`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      